<!-- 学员信息 -->
<template>
	<div class="main-container">
		<div class="side-header">
			<div class="name">学员信息</div>
		</div>
		<div class="main-content">
			<div class="top">
				<el-form :inline="true" ref="form" :model="form" :rules="rules" label-position="left"
				>
					<el-row :gutter="10">
            <el-col :span="8">
							<el-form-item label="届次">
								<el-select v-model="form.year" placeholder="请选择届次" @change="getClassListByYear">
									<el-option v-for="item in yearList" :key="'year'+item.value" :label="item.courseName" :value="item.ipType">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="班级">
								<el-select v-model="form.collegeClassId" placehoslder="请选择班级">
									<el-option v-for="item in collegeClassList" :key="'class'+item.college_class_id" :label="item.class_name" :value="item.college_class_id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
            <el-col :span="8">
							<el-form-item label="课程">
								<el-select v-model="form.ipType" placeholder="请选择课程">
									<el-option v-for="item in ipTypeCourseList" :key="'ipType'+item.ipType" :label="item.courseName" :value="item.ipType">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						
					</el-row>
					<el-row :gutter="24">
						<el-col :span="8">
							<el-form-item label="姓名">
								<el-input v-model="form.studentName" placeholder="请输入姓名"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="手机号">
								<el-input v-model="form.telephone" placeholder="请输入手机号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item class="select-isStudy" label="是否安排上课">
								<el-select v-model="form.isStudy" placeholder="请选择">
									<el-option v-for="(item,index) in isStudyList" :key="'study'+index" :label="item.label" :value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
          <el-row :gutter="24" class="flex">
						<el-col :span="4" style="margin: 0 0 30px auto">
							<el-button type="primary" v-preventReClick @click="getStudentList">查询</el-button>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<div class="bottom">
				<!-- <el-form ref="form" :model="formInline" label-position="left" label-width="80px">
					<el-checkbox v-model="formInline.checked">已选择<span>{{formInline.num}}</span>项</el-checkbox>
					<el-select v-model="projectNum" placeholder="请选择">
						<el-option v-for="item in projectList" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-button type="primary" @click="toggleSelection()" class="cancel">取消选择</el-button>
					<el-button type="primary" class="determine">确定</el-button>
				</el-form> -->
				<el-table ref="multipleTable" :data="studentList" tooltip-effect="dark" style="width: 100%"
					@selection-change="handleSelectionChange">
					<el-table-column type="selection" width="30"></el-table-column>
					<el-table-column prop="studentId" label="ID" width="55"></el-table-column>
					<el-table-column prop="name" label="姓名" width="80"></el-table-column>
					<el-table-column prop="telephone" label="手机号" width="120"></el-table-column>
					<el-table-column prop="courseName" label="课程" width="150"></el-table-column>
          <el-table-column  label="届次/班级" width="150">
            <template slot-scope="scope">
							{{scope.row.year}}-{{scope.row.collegeClassName}}					
						</template>
          </el-table-column>
					<el-table-column prop="isStudy" label="是否安排上课">
						<template slot-scope="scope">
							{{scope.row.isStudyStr}}				
						</template>
					</el-table-column>
					<el-table-column prop="studentCourseNumber" label="上课课时">
					</el-table-column>
					<el-table-column prop="operation" label="操作">
						<template slot-scope="scope">
							<el-button size="mini" @click="getStudentInfo(scope.row.studentId)">详情</el-button>					
						</template>
					</el-table-column>
				</el-table>
        <el-pagination 
					background 
					:current-page="page" 
          :hide-on-single-page="true"
					@current-change="getStudentList"
					layout="prev, pager, next" 
					:page-count="totalPage">
				</el-pagination>	
        <el-empty description="暂无数据" v-if="!studentList.length"></el-empty>
			</div>
		</div>
    <el-dialog :visible.sync="dialogVisible" width="700px" class="dialogbox" :data="studentInfoData">
      <div class="dialog-title">
        <h5>{{studentInfoData.name}}——详细信息</h5>
      </div>
      <div class="close" @click="dialogVisible = false"><img src="@/assets/icons/grayclose.png" ></div>
      <div class="dialog-content">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">姓名：</div>
              <div class="text">{{studentInfoData.name}}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">生日：</div>
              <div class="text">{{studentInfoData.birthday | stringData}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">邮箱：</div>
              <div class="text">{{studentInfoData.email}}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">手机号：</div>
              <div class="text">{{studentInfoData.telephone}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="items flex flex-start">
              <div class="label">地址：</div>
              <div class="text">{{studentInfoData.address}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">学校：</div>
              <div class="text">{{studentInfoData.school}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">届次：</div>
              <div class="text">{{studentInfoData.school}}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">班级：</div>
              <div class="text">{{studentInfoData.school}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">课程选择：</div>
              <div class="text">{{studentInfoData.courseName}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible2" width="700px" class="dialogbox dialogbox2" :data="hasClassData">
      <div class="dialog-title">
        <h5>{{dialogData.name}}——详细信息</h5>
      </div>
      <div class="close" @click="dialogVisible2 = false"><img src="@/assets/icons/grayclose.png" ></div>
      <div class="dialog-content">
        <el-form :inline="true" ref="form" label-position="left"
      label-width="80px">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="考勤">
                <el-select v-model="attendId" placeholder="请选择">
                  <el-option v-for="(item,index) in attendList" :key="'attend'+index" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>							
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input type="textarea" :rows="3" placeholder="">{{hasClassData.remarks}}</el-input>
              </el-form-item>
            </el-col>							
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button size="mini" class="attendBtn">确认</el-button>					
            </el-col>							
          </el-row>
        </el-form>												
      </div>
    </el-dialog>
	</div>
</template>

<script>
import moment from 'moment';
	export default {
		filters: {
			stringData: function (date) {
				return moment(date * 1000).format("YYYY-MM-DD");
			},
		},
		data() {
			return {
				form: {
					studentName: '',
					telephone: '',
					isStudy: null,
					ipType: null,
          year:null,
          collegeClassId:null,
				},
        //查询信息											
				rules: {
					name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					mobile: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
				},
				isStudyList: [{
          label:'未安排',
          value:0,
        },{
          label:'已安排',
          value:1,
        }],
				ipTypeCourseList: [],
        yearList:[{
          label:'2020届',
          value:2020,
        },{
          label:'2021届',
          value:2021,
        }],
        collegeClassList:[],

				projectNum: [],
				projectList:[],
				studentList: [],//学员信息表格				
				studentInfoData:[],//学员详细信息
				hasClassData:[],//考勤状态
				multipleSelection: [],
				dialogVisible: false,
				dialogVisible2:false,
				gridData: [],

        dialogData:{},
        attendId:1,
				attendList:[{
          label:'缺课',
          value:0,
        },{
          label:'完成',
          value:1,
        }],	
        page:1,
        totalPage:null,
			};
		},
		created(){
			this.getStudentList(this.page);
      this.getCommonList();
			this.getClassListByYear();
		},
		methods: {
      getCommonList(){
        this.$http.get('/api/web/common/getCommonList')
				.then(res => {
					this.ipTypeCourseList = res.data.ipTypeCourseList;															
				})
      },
      getClassListByYear(){
        const data={
          year:this.form.year
        }
        this.$http.post('/api/web/student/getClassListByYear',data)
				.then(res => {	
					this.collegeClassList = res.data.collegeClassList;															
				})
      },
      toggleSelection(rows) {
          if (rows) {
              rows.forEach(row => {
              this.$refs.multipleTable.toggleRowSelection(row);
              });
          } else {
            this.$refs.multipleTable.clearSelection();
          }
      },
      handleSelectionChange(val) {
          this.multipleSelection = val;
      },
			//获取学员信息接口
			getStudentList(val){
        const data=Object.assign(this.form,{page:val})
				this.$http.post(`/api/web/student/getStudentList`,data)
				.then(res => {	
					this.studentList = res.data.studentList;
          this.totalPage=res.data.totalPage;															
				})
			},
			//获取学员详情接口
			getStudentInfo(id){
        this.dialogVisible = true
				this.$http.get('/api/web/student/getStudentInfo?studentId='+id)
				.then(res => {
					this.studentInfoData = res.data.studentInfo;
				})
			},
			//获取考勤状态接口
			hasClass(){
				this.$http.get('/api/web/student/hasClass')
				.then(res => {
					this.hasClassData = res.data.studentInfo;
				})
			},
			onCheck(item){
        // console.log(item)
        this.dialogData=item;
				this.dialogVisible2 = true;
			},
		}	
	}
</script>

<style lang="less" scoped>
	.main-container {
		.side-header {
			background: #fff;
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 68px;
			padding: 0 20px 0 40px;
			border-bottom: 1px solid #F5F5F5;
			.name {
				font-size: 16px;
				color: #333;
			}
		}
		.main-content {
			/deep/.top {
				background: #fff;
				margin-bottom: 10px;
				padding: 30px 40px 8px 30px;
        .select-isStudy{
          .el-form-item__label{
						width: 120px;
					}
          .el-form-item__content{
            width: calc(100% - 120px);
          }
        }
				.el-button {
					float: left;
					width: 140px;
				}  
				.el-form-item{
					width: 100%;
					margin-right: 0;
					.el-form-item__label{
						text-align: right;
					}
				}
				.el-form-item__content{
					width: calc(100% - 80px);
					.el-select{width: 100%;}
				}
			}
			/deep/.bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
				background: #fff;
				padding: 20px 40px 40px;
				text-align: left;
				.el-form {
          width: 100%;
					padding-bottom: 30px;
					border-bottom: solid 2px #dedede;
				}
				.el-checkbox,
				.el-select {
					margin-right: 10px;
				}
				.cancel,
				.determine {
					width: 140px;
				}
				.cancel {
					background-color: white;
					color: #D71A18;
				}
				.el-table{
          margin-bottom: 30px;
					thead{
            color: #333;
          }
					.cell{
						text-align: center;
						white-space: nowrap;
					}
					th.el-table__cell>.cell{
						text-align: center;
					}
					.el-button{
						border: none;
						font-size: 14px;
					}
				}								
			}
		}
    /deep/.dialogbox{
      text-align: left;
      .el-dialog{
        width: 100%;
      }
      .el-dialog__header{
        display: none;
      }
      .el-dialog__body{
        padding: 0;
      }
      .close{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 36px;
        height: 36px;
        cursor: pointer;
        img{
          display: block;
          width: 36px;
          height: 36px;
        }
      }
      .dialog-title{
        font-size: 18px;
        text-align: center;
        color: #666666;	
        padding:35px 40px;
        border-bottom: solid 2px #dedede;
        h5{
          margin-top: 0;
          margin: 0;
        }
      }
      .dialog-content{
        min-height: 500px;
        padding: 30px 40px;					
        .items{
          margin-bottom: 15px;
          .label{
            font-size: 16px;
            height: 36px;
            line-height: 36px;
            color: #666;
          }
          .text{
            font-size: 16px;
            height: 36px;
            line-height: 36px;
            color: #333;
          }
          .img{
            img{
              display: block;
              width: 184px;
              margin-right: 30px;
            }
          }
        }
      }
    }
    /deep/.dialogbox2{
      .el-form {
        border-bottom: none;
        .el-form-item{
          width: 100%;
          .el-form-item__content{
            width: calc(100% - 80px);
          }
          
        }
        .attendBtn{
          display: block;
          width: 160px;
          height: 48px;
          line-height: 48px;
          background: #d71a18;
          border-radius: 4px;
          color: #ffffff;
          font-size: 18px;
          text-align: center;
          margin: 0 auto;
          padding: 0;
        }
      }
    }
	}
</style>
